<template>
  <div>
    <manage-page-actions-layout>
      <template v-slot:actions="{ actionStyle }">
        <div class="button-center">
          <md-button
            class="md-primary"
            :style="actionStyle"
            @click="saveDevice()"
            >Save</md-button
          >
          <md-button
            class="btn-outline"
            :style="actionStyle"
            @click="closeDialog"
            >Cancel</md-button
          >
        </div>
      </template>
    </manage-page-actions-layout>
  </div>
</template>
<script>
import ManagePageActionsLayout from "../../../../components/layouts/manage-page-layout/ManagePageActionsLayout.vue";
export default {
  components: { ManagePageActionsLayout },
  inject: ["manageDevice", "handleSave", "closeDialog"],
  methods: {
    saveDevice() {
      this.handleSave();
    },
  },
};
</script>

<style scoped>
.button-center {
  margin-left: 35%;
}
</style>
