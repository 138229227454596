import MasterService from "../../../services/common-services/MasterService";
import ManageDeviceService from "../../../services/module-services/device/ManageDeviceService";
import NavigationService from "../../../services/common-services/NavigationService";

const ManageDeviceHandler = {
  mixins: [ManageDeviceService, MasterService, NavigationService],
  methods: {
    async submit() {
      if (!(await this.deviceForm.isValid())) return;
      this.saveDevice(() => {});
    },
    navigateToDevices() {
      this.navigateTo("devices");
    },
  },
  provide() {
    return {
      handleSave: this.submit,
      loadDevice: this.loadDevice,
    };
  },
  template: `<div><slot></slot></div>`,
};

export default ManageDeviceHandler;

export const withManageDeviceHandler = (WrappedComponent) => {
  return {
    props: WrappedComponent.props,
    template: `<ManageDeviceHandler><WrappedComponent v-bind="$props"></WrappedComponent></ManageDeviceHandler>`,
    components: { ManageDeviceHandler, WrappedComponent },
  };
};
