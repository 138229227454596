<template>
  <div>
    <list-layout>
      <loading :active="app.showLoader.value" :can-cancel="true"></loading>
      <template v-slot:header-actions>
        <primary-button
          toolTip="Add New Device"
          style="margin-right: 15px"
          @click="handleAddClick()"
          >Add
        </primary-button>
      </template>
      <template v-slot:content>
        <device-list-table @edit-device="onEditDevice"></device-list-table>
      </template>
    </list-layout>
    <md-dialog :md-active.sync="showDialog.val">
      <div class="modal-size-medium">
        <manage-device-page :device="selectedItem"></manage-device-page>
      </div>
    </md-dialog>
  </div>
</template>

<script>
import PrimaryButton from "../../../../components/controls/button/PrimaryButton.vue";
import ListLayout from "../../../../components/layouts/list-layout/ListLayout.vue";
import ManageDevicePage from "../../device/manage-device/ManageDevicePage.vue";
import { withManageDeviceHandler } from "../../../../handlers/module-handlers/device/ManageDeviceHandler";
import DeviceListTable from "./DeviceListTable.vue";
export default {
  components: {
    ListLayout,
    DeviceListTable,
    PrimaryButton,
    ManageDevicePage: withManageDeviceHandler(ManageDevicePage),
  },
  inject: ["featureDetails", "app", "closeDialog", "showDialog", "openDialog"],
  methods: {
    handleAddClick() {
      this.selectedItem = { id: "" };
      this.openDialog();
    },

    onEditDevice(item) {
      this.selectedItem = item;
      this.openDialog();
    },
  },
  created() {},
  data() {
    return {
      selectedItem: { id: "" },
    };
  },
  provide() {
    return {
      closeDialog: this.closeDialog,
    };
  },
};
</script>
