<template>
  <div class="md-content md-table-content">
    <loading :active="app.showLoader.value" :can-cancel="true"></loading>
    <md-table
      :value="
        list.pages[listInput.page] && list.pages[listInput.page].items
          ? list.pages[listInput.page].items
          : []
      "
      class="globe-activity md-table-global-sales Device responsive"
    >
      <!-- md-fixed-header -->
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Name"
          >{{ item.title }} {{ item.name }}</md-table-cell
        >
        <md-table-cell md-label="Description">{{
          item.description
        }}</md-table-cell>
        <md-table-cell md-label="Serial No.">{{ item.serialNo }}</md-table-cell>
        <md-table-cell md-label="Status">
          {{ getSerialno(item.status) }}
        </md-table-cell>

        <md-table-cell md-label="Client">{{
          getSerialno(
            item.locations.length > 0 ? orgName(item.locations[0].orgID) : ""
          )
        }}</md-table-cell>
        <md-table-cell md-label="Activation Date">{{
          getSerialno(
            item.locations.length > 0 ? item.locations[0].activationDate : ""
          )
        }}</md-table-cell>
        <md-table-cell md-label="Actions">
          <md-icon
            class="edit-icon"
            style="padding-right: 20px"
            @click.native="handleEditClick(item)"
            >edit</md-icon
          >
          <md-icon class="delete-icon" @click.native="handleDeleteClick(item)"
            >delete</md-icon
          >
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  inject: ["list", "listInput", "handleDeleteClick", "app"],
  data() {
    return {};
  },
  methods: {
    orgName(orgId) {
      return this.organisations.find((org) => org.id === orgId)
        ? this.organisations.find((org) => org.id === orgId).name
        : "";
    },
    handleEditClick(item) {
      this.$emit("edit-device", item);
    },
    getSerialno(item) {
      if (item == null || item == "") {
        return "Unknown";
      } else {
        return item;
      }
    },
  },
  computed: {
    ...mapState({
      organisations: (state) => state.profile.userProfile.organisations,
    }),
  },
};
</script>

<style lang="scss" scoped>
.globe-activity {
  height: calc(100vh - 400px);
  overflow: auto;
}
.md-table-cell {
  // padding: 2px 8px;
  font-size: 0.8rem;
  height: 45px;
}
.md-table-row {
  color: rgb(0, 6, 97);
}
.md-table-global-sales .md-table-row .md-table-cell:first-child {
  font-weight: 500;
}
.Device.responsive td:nth-of-type(1):before {
  content: "Name";
}
.Device.responsive td:nth-of-type(2):before {
  content: "Description";
}
.Device.responsive td:nth-of-type(3):before {
  content: "Serial No.";
}
.Device.responsive td:nth-of-type(4):before {
  content: "Status";
}
.Device.responsive td:nth-of-type(5):before {
  content: "Client";
}
.Device.responsive td:nth-of-type(6):before {
  content: "Activation Date";
}
.Device.responsive td:nth-of-type(7):before {
  content: "Actions";
}
</style>
