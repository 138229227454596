<template>
  <div>
    <loading :active="app.showLoader.value" :can-cancel="true"></loading>
    <device-form />
    <manage-device-actions />
  </div>
</template>
<script>
import ManageDeviceActions from "./ManageDeviceActions.vue";
import DeviceForm from "./DeviceForm.vue";
export default {
  inject: ["deviceForm", "manageDevice", "loadDevice", "app"],
  components: {
    DeviceForm,
    ManageDeviceActions,
  },
  props: {
    device: {
      type: Object,
      default() {
        return {
          id: "",
        };
      },
    },
  },
  created() {
    if (this.device.id) {
      this.deviceForm.postData.id = this.device.id;
      this.manageDevice.deviceId = this.device.id;
      this.loadDevice();
    }
  },
};
</script>
