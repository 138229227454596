<template>
  <manage-page-form-layout title="Device Information">
    <template v-slot:content>
      <ValidationListener
        @getIsValid="(isValid) => (deviceForm.isValid = isValid)"
      >
        <div class="form-vertical">
          <div class="mb-3">
            <ValidationHandler rules="required|alpha_dash" v-slot="{ error }">
              <text-input
                label="Name *"
                placeholder="Name *"
                icon="devices"
                v-model="deviceForm.postData.name"
                :required="true"
                :error="error"
              />
            </ValidationHandler>
          </div>
          <div class="mb-3">
            <ValidationHandler rules="required" v-slot="{ error }">
              <text-input
                label="Serial Number *"
                placeholder="Serial Number *"
                icon="perm_device_information"
                v-model="deviceForm.postData.serialNo"
                :required="true"
                :error="error"
              />
            </ValidationHandler>
          </div>
          <div class="mb-3">
            <ValidationHandler rules="required" v-slot="{ error }">
              <text-input
                label="Description *"
                placeholder="Description *"
                icon="description"
                v-model="deviceForm.postData.description"
                :required="true"
                :error="error"
              />
            </ValidationHandler>
          </div>
          <!-- <div class="mb-3">
            <ValidationHandler rules="required" v-slot="{ error }">
              <select-input
                name="Status"
                icon="payment"
                displayName="Status *"
                placeHolder="Status *"
                :options="
                  deviceStatusList.map((x) => ({
                    value: x.value,
                    text: x.displayValue,
                  }))
                "
                v-model="deviceForm.postData.status"
                :required="true"
                :error="error"
              />
            </ValidationHandler>
          </div> -->
        </div>
      </ValidationListener>
    </template>
  </manage-page-form-layout>
</template>
<script>
import ManagePageFormLayout from "../../../../components/layouts/manage-page-layout/ManagePageFormLayout.vue";
export default {
  components: { ManagePageFormLayout },
  computed: {
    deviceStatusList: () => {
      return [
        { value: "rented", displayValue: "Rented" },
        { value: "leased", displayValue: "Leased" },
      ];
    },
  },
  inject: ["deviceForm", "master"],
};
</script>
